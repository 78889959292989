<template>
  <div class="accordion-item">
    <!-- accordion:headline START -->
    <div class="headline-holder">
      <slot name="location-header"></slot>
    </div>
    <!-- accordion:headline END -->

    <!-- accordion:content START -->
    <div class="content-holder">
      <slot name="location-content"></slot>
    </div>
    <!-- accordion:content END -->

  </div>
</template>

<script>
export default {
  name: 'location-accordion-item',
};
</script>
